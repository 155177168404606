
import MixedWidget2 from "@/components/widgets/mixed/Home.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Page from "@/layout/Page.vue";
import { AuthModule } from "@/store/modules";
import { defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "dashboard",
  components: {
    MixedWidget2,
    Page,
  },
  setup() {
    const store = useStore();
    const authModule = getModule(AuthModule, store);
    const user = authModule.user;
    const router = useRouter();

    onMounted(() => {
      setCurrentPageTitle("Inicio");

      if (user.roleId === "GESTION_EXT") {
        router.push(`/listados/recoleccion-de-datos`);
      }
    });
  },
});
