import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex pt-4" }
const _hoisted_2 = { class: "col-lg-4 fw-bold fs-6" }
const _hoisted_3 = { class: "drawerValue" }
const _hoisted_4 = { class: "d-flex pt-6" }
const _hoisted_5 = { class: "col-lg-4 fw-bold fs-6" }
const _hoisted_6 = { class: "drawerValue" }
const _hoisted_7 = { class: "d-flex pt-6" }
const _hoisted_8 = { class: "col-lg-4 fw-bold fs-6" }
const _hoisted_9 = { class: "drawerValue" }
const _hoisted_10 = { class: "d-flex pt-6" }
const _hoisted_11 = { class: "col-lg-4 fw-bold fs-6" }
const _hoisted_12 = { class: "drawerValue" }
const _hoisted_13 = { class: "pt-6" }
const _hoisted_14 = { class: "d-flex pt-4" }
const _hoisted_15 = { class: "col-lg-4 fw-bold fs-6" }
const _hoisted_16 = { class: "drawerValue" }
const _hoisted_17 = { class: "pt-6" }
const _hoisted_18 = { class: "d-flex pt-4" }
const _hoisted_19 = { class: "col-lg-4 fw-bold fs-6" }
const _hoisted_20 = { class: "drawerValue" }
const _hoisted_21 = { class: "d-flex pt-4" }
const _hoisted_22 = { class: "col-lg-4 fw-bold fs-6" }
const _hoisted_23 = { class: "drawerValue" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_drawer = _resolveComponent("el-drawer")!

  return (_openBlock(), _createBlock(_component_el_drawer, {
    ref: "drawerRef",
    modelValue: _ctx.drawerFicha,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.drawerFicha) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.profesional.nombre + " " + _ctx.profesional.apellidos), 1),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("listados.servicioDetalle.drawer.profesional.dni.label")), 1),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.profesional.dni), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t("listados.servicioDetalle.drawer.profesional.correo.label")), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.profesional.email), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("listados.servicioDetalle.drawer.profesional.telefono.label")), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.profesional.telefono), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("listados.servicioDetalle.drawer.profesional.nacimiento.label")), 1),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.fechaNacimiento), 1)
        ]),
        _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.$t(
            "listados.servicioDetalle.drawer.profesional.datosProfesionales.label"
          )), 1),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("listados.servicioDetalle.drawer.profesional.titulacion.label")), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.titulacion), 1)
        ]),
        _createElementVNode("h3", _hoisted_17, _toDisplayString(_ctx.$t("listados.servicioDetalle.drawer.profesional.datosContrato.label")), 1),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("listados.servicioDetalle.drawer.profesional.contrato.label")), 1),
          _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.contrato), 1)
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("listados.servicioDetalle.drawer.profesional.jornada.label")), 1),
          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.jornada), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}