interface MenuItem {
  heading?: string;
  pages?: Array<MenuItem>;
  route?: string;
  svgIcon?: string;
  fontIcon?: string;
  allowedRoles?: string[];
  link?: string;
}

const DocMenuConfig: Array<MenuItem> = [
  {
    pages: [
      {
        heading: "menu.dashboard",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
        allowedRoles: ["superadmin", "admin"],
      },
    ],
  },
  {
    heading: "menu.servicios",
    pages: [
      {
        heading: "menu.serviciosIndividuales",
        route: "/listados/serviciosprestados",
        fontIcon: "bi bi-calendar2-check",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.serviciosProgramados",
        route: "/listados/servicios-programados",
        fontIcon: "bi bi-shop",
        allowedRoles: ["superadmin", "admin"],
      },
    ],
  },
  {
    heading: "menu.personas",
    pages: [
      {
        heading: "menu.profesionales",
        route: "/listados/profesionales",
        fontIcon: "bi bi-briefcase",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.bolsaProfesionalesCA",
        route: "/listados/profesionalesCA",
        fontIcon: "bi bi-briefcase",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.familias",
        route: "/listados/familias",
        fontIcon: "bi bi-house-door",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.menores",
        route: "/listados/menores",
        fontIcon: "bi bi-emoji-smile",
        allowedRoles: ["superadmin", "admin"],
      },
    ],
  },
  {
    heading: "menu.solicitudes",
    pages: [
      {
        heading: "menu.solicitudesServicios",
        route: "/solicitudes/servicios",
        fontIcon: "bi bi-calendar2-plus",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.solicitudesProfesionales",
        route: "/solicitudes/profesionales",
        fontIcon: "bi bi-briefcase",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.solicitudesFamilias",
        route: "/solicitudes/familias",
        fontIcon: "bi bi-house-door",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.solicitudesProfesionalesModificacion",
        route: "/solicitudes/profesionales-modificacion",
        fontIcon: "bi bi-briefcase",
        allowedRoles: ["superadmin", "admin"],
      },
    ],
  },
  {
    heading: "menu.planCorresponsables",
    pages: [
      {
        heading: "menu.proyectosNv2",
        route: "/listados/proyectosN2",
        fontIcon: "bi bi-file-earmark-text",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.datosProyectosNv2",
        route: "/listados/recoleccion-de-datos",
        fontIcon: "bi bi-file-earmark-text-fill",
        allowedRoles: ["superadmin", "externo"],
      },
    ],
  },
  {
    heading: "menu.usuarios",
    pages: [
      {
        heading: "menu.usuariosListado",
        route: "/listados/personal",
        fontIcon: "bi bi-people",
        allowedRoles: ["superadmin", "admin"],
      },
      {
        heading: "menu.organismosListado",
        route: "/listados/organismos",
        fontIcon: "bi bi-bank",
        allowedRoles: ["superadmin"],
      },
    ],
  },
  {
    heading: "menu.documentacion",
    pages: [
      {
        heading: "menu.manual",
        link: "https://iam-3.gitbook.io/gestion-corresponsables",
        fontIcon: "bi bi-life-preserver",
        allowedRoles: ["*"],
      },
    ],
  },
];

export default DocMenuConfig;
export { MenuItem };
