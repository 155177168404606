
import IncluirMenorServicioModal from "@/components/modals/IncluirMenorServicioModal.vue";
import DrawerHorario from "@/components/servicios/DrawerHorario.vue";
import ProfesionalesTablaAsignados from "@/components/servicios/TablaProfesionalesAsignados.vue";
import ProfesionalesTablaDisponibles from "@/components/servicios/TablaProfesionalesDisponibles.vue";
import ServiciosColectivosSolicitadosTabla from "@/components/servicios/TablaServiciosColectivosSolicitados.vue";
import { useDropZone } from "@/composable/useDropZone";
import iconosServicios from "@/core/data/iconosServicios";
import { nombreIdioma } from "@/core/data/idiomas";
import lugaresEspaciosColectivos from "@/core/data/lugaresEspaciosColectivos";
import { dias, horarios } from "@/core/data/tiemposFamilias";
import vehiculos from "@/core/data/vehiculos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { FormularioEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { Disponibilidad, Reserva } from "@/store/models";
import {
  AuthModule,
  OrganismosModule,
  ProyectosN2ListadoModule,
  ServiciosProgramadosModule,
} from "@/store/modules";
import { Documento, IAMFile } from "@/types";
import { ArrowDown, Link } from "@element-plus/icons-vue";
import { useClipboard } from "@vueuse/core";
import { ElMessage, ElNotification } from "element-plus";
import moment from "moment";
import "moment/locale/es";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import {
  computed,
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface ServicioProgramado {
  comarcaId: string;
  iconoCls: string;
  nombre: string;
  descripcion: string;
  plazas: number;
  direccion?: string | null;
  inscripcionesAbiertas: boolean;
  lugarPrestacion?: string | null;
  lugarEspacioColectivo?: string | null;
  otrosEspacioColectivo?: string | null;
  inicioPrevisto: Date;
  finPrevisto: Date;
  fechas: Array<string> | [];
  tiempos: Disponibilidad[];
  adjuntosNuevos?: IAMFile[];
  adjuntosEliminar?: string[];
  proyectoId?: string;
}

const formularioBaseUrl = process.env.VUE_APP_FORMULARIOS_URL;

export default defineComponent({
  name: "servicio-programado-detalle",
  components: {
    Page,
    ErrorMessage,
    DrawerHorario,
    ServiciosColectivosSolicitadosTabla,
    ProfesionalesTablaAsignados,
    ProfesionalesTablaDisponibles,
    ArrowDown,
    IncluirMenorServicioModal,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();
    const token = route.params.id;
    const isCreate = !token;
    const store = useStore();
    const module = getModule(ServiciosProgramadosModule, store);
    const authModule = getModule(AuthModule, store);
    const userComarcaId = authModule.user.comarcaId;

    const moduleOrganismos = getModule(OrganismosModule, store);
    const comarcas = computed(() => moduleOrganismos.organismosListado);

    const cargaDetalle = ref(false);
    const cargaListadoProfesionales = ref(false);
    const crearServicioProgramado = ref(false);
    const actualizarServicioProgramado = ref(false);

    const proyectosModule = getModule(ProyectosN2ListadoModule, store);
    const proyectosN2 = computed(() => {
      return proyectosModule.proyectosN2Comarca;
    });

    onBeforeMount(async () => {
      cargaDetalle.value = true;
      module.clearService();
      try {
        await moduleOrganismos.fetchAll();

        if (!isCreate) {
          await module.fetchById({
            comarcaId: userComarcaId,
            id: token as string,
          });
        }
      } catch (err) {
        Swal.fire({
          text: t("formularios.servicioProgramadoDetalle.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
      if (!isCreate || userComarcaId) {
        await proyectosModule.fetchByComarca({
          comarcaId: userComarcaId,
          comarcaIdFiltro: module.servicio?.comarcaId as string,
        });
      }
    });

    const comarcaValor = ref<string>();
    const servicioId = ref<string>();

    const datosServicio = computed(() => {
      return module.servicio;
    });
    const solicitudes = computed(() => {
      return (
        module.servicio?.peticionFormularios
          ?.map((ele) => {
            const familyForm = ele.peticionFormulario ?? { datosSolicitud: {} };
            return {
              id: ele.id,
              fecha: ele.createdOn,
              nombre:
                ele.familia?.nombre ??
                ele.datosSolicitud?.nombre ??
                familyForm.datosSolicitud?.nombre ??
                "",
              apellidos:
                ele.familia?.apellidos ??
                ele.datosSolicitud?.apellidos ??
                familyForm.datosSolicitud?.apellidos ??
                "",
              menores: ele.datosSolicitud.menores,
              dni: ele.dni ?? ele.datosSolicitud?.dni ?? "",
              telefono:
                ele.familia?.telefono ?? ele.datosSolicitud?.telefono ?? "",
              email:
                ele.familia?.email ??
                ele.datosSolicitud?.email ??
                familyForm.datosSolicitud?.email ??
                "",
              familiaAprobada: Boolean(ele.familiaId),
              familiaFormularioId: ele.peticionFormularioId,
              familiaId: ele.familiaId,
              familiaEstado: ele.familiaId
                ? "ADMITIDA"
                : ele.peticionFormulario?.estado ?? "PENDIENTE",
              estado: ele.estado,
              localidad: ele.familia?.localidad,
              proyectoId: ele.proyectoId,
            };
          })
          .sort(function (a, b) {
            return (b.familiaAprobada ? 1 : 0) - (a.familiaAprobada ? 1 : 0);
          }) ?? []
      );
    });

    const encuestas = computed(() => {
      return module.servicio?.servicio?.respuestasEncuesta ?? [];
    });

    const menores = computed(() => {
      return (
        module.servicio?.peticionFormularios
          ?.filter((ele) => ele.estado === FormularioEstado.incorporado)
          .map((ele) => {
            const respuestaEncuesta = encuestas.value.find(
              (encuesta) => encuesta.familiaId === ele.familiaId
            );

            return {
              id: ele.id,
              fecha: ele.createdOn,
              nombre: ele.familia?.nombre ?? ele.datosSolicitud?.nombre ?? "",
              apellidos:
                ele.familia?.apellidos ?? ele.datosSolicitud?.apellidos ?? "",
              menores: ele.datosSolicitud.menores,
              dni: ele.dni ?? ele.datosSolicitud?.dni ?? "",
              telefono:
                ele.familia?.telefono ?? ele.datosSolicitud?.telefono ?? "",
              email: ele.familia?.email ?? ele.datosSolicitud?.email ?? "",
              familiaAprobada: Boolean(ele.familiaId),
              familiaFormularioId: ele.peticionFormularioId,
              familiaId: ele.familiaId,
              direccion:
                ele.familia?.direccion ?? ele.datosSolicitud?.direccion ?? "",
              menoresNombres: ele.datosSolicitud.menores
                ?.map((menor) => menor.nombre + " " + menor.apellidos)
                .join(", "),
              respuestaEncuesta,
              estado: ele.estado,
              proyectoId: ele.proyectoId,
            };
          }) ?? []
      );
    });

    const profesionalesAsignados = computed(() => {
      return !!datosServicio.value?.servicio.profesionalesAsignados?.length;
    });
    const profesionalesAsignadosFilas = computed(() => {
      return datosServicio.value?.servicio.profesionalesAsignados ?? [];
    });
    const url = computed(() => {
      if (!datosServicio.value?.formularioId || isCreate) return "";
      return `${formularioBaseUrl}#/home?act=${datosServicio.value.formularioId}`;
    });

    const { text, copy, copied, isSupported } = useClipboard({ source: url });

    watch(datosServicio, () => {
      if (!datosServicio.value) return;

      resetForm({
        values: {
          comarcaId: datosServicio.value.comarcaId,
          iconoCls: datosServicio.value.iconoCls,
          nombre: datosServicio.value.nombre,
          descripcion: datosServicio.value.descripcion,
          lugarPrestacion: "ESPACIO",
          lugarEspacioColectivo:
            datosServicio.value.servicio.lugarEspacioColectivo,
          otrosEspacioColectivo:
            datosServicio.value.servicio.otrosEspacioColectivo,
          plazas: datosServicio.value.plazas,
          direccion: datosServicio.value.servicio.direccion,
          inscripcionesAbiertas: datosServicio.value.inscripcionAbierta,
          inicioPrevisto: datosServicio.value.servicio.inicioPrevisto,
          finPrevisto: datosServicio.value.servicio.finPrevisto,
          fechas: datosServicio.value.servicio.fechas,
          tiempos: datosServicio.value.servicio.tiempos,
          adjuntosNuevos: [],
          proyectoId: datosServicio.value.proyectoId ?? "",
        },
      });
      documentos.value = datosServicio.value.documentos || [];
      comarcaValor.value = datosServicio.value.comarcaId;
      servicioId.value = datosServicio.value.servicio.id;

      setCurrentPageBreadcrumbs(datosServicio.value?.nombre ?? "", [
        t("menu.servicios"),
        {
          title: t("menu.serviciosProgramados"),
          path: "/listados/servicios-programados",
        },
      ]);
    });

    const error = computed(() => module.errors);
    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });

    onMounted(() => {
      if (isCreate) {
        setCurrentPageBreadcrumbs(t("menu.nuevo"), [
          t("menu.servicios"),
          t("menu.serviciosProgramados"),
        ]);

        resetForm({
          values: {
            comarcaId: userComarcaId,
            iconoCls: "",
            nombre: "",
            descripcion: "",
            lugarPrestacion: "ESPACIO",
            lugarEspacioColectivo: "",
            otrosEspacioColectivo: "",
            plazas: 5,
            direccion: "",
            inscripcionesAbiertas: false,
            inicioPrevisto: new Date(),
            finPrevisto: new Date(+new Date() + 1000 * 60 * 60 * 24 * 7),
            fechas: [],
            tiempos: [{ dias: [], horas: [] }],
            adjuntosNuevos: [],
            proyectoId: "",
          },
        });
      }

      // TODO: Rellenar el breadcrumb con algun dato del servicio
    });

    const schema = Yup.object({
      comarcaId: Yup.string().ensure().required().label("Comarca"),
      iconoCls: Yup.string()
        .typeError("Icono es obligatorio")
        .required()
        .label("Icono"),
      nombre: Yup.string().required().label("Nombre"),
      descripcion: Yup.string().required().label("Descripción"),
      lugarPrestacion: Yup.string(),
      lugarEspacioColectivo: Yup.string()
        .required()
        .label("Lugar del espacio colectivo"),
      otrosEspacioColectivo: Yup.string(),
      plazas: Yup.number()
        .typeError("El número de plazas es obligatorio")
        .required()
        .label("El número de plazas"),
      direccion: Yup.string().required().label("Dirección"),
      inscripcionesAbiertas: Yup.boolean().required(),
      inicioPrevisto: Yup.date().required(),
      finPrevisto: Yup.date().required(),
      fechas: Yup.array(),
      tiempos: Yup.array()
        .of(
          Yup.object({
            dias: Yup.array().ensure().min(1),
            horas: Yup.array().ensure().min(1),
          })
        )
        .ensure()
        .min(1)
        .test(
          "elemento-completo",
          t("listados.servicioDetalle.tiempos.errorFaltaDiaHora"),
          (value) =>
            !value?.some((ele) => !ele?.dias?.length || !ele?.horas?.length)
        ),
      adjuntosNuevos: Yup.array(),
      adjuntosEliminar: Yup.array(),
      proyectoId: Yup.string().notRequired(),
    });

    const { resetForm, handleSubmit, errors } = useForm<ServicioProgramado>({
      validationSchema: schema,
    });

    const { value: comarcaId } = useField("comarcaId");
    const { value: iconoCls } = useField("iconoCls");
    const { value: nombre } = useField("nombre");
    const { value: descripcion } = useField("descripcion");
    const { value: lugarPrestacion } = useField("lugarPrestacion");
    const { value: lugarEspacioColectivo } = useField("lugarEspacioColectivo");
    const { value: otrosEspacioColectivo } = useField("otrosEspacioColectivo");
    const { value: plazas } = useField<number>("plazas");
    const { value: inscripcionesAbiertas } = useField("inscripcionesAbiertas");
    const { value: direccion } = useField("direccion");
    const { value: proyectoId } = useField("proyectoId");

    const busquedaProfesional = ref(false);
    const profesionalesListado = computed(() => module.profesionales);

    const vehiculoPropio = ref<string>();
    const disponeTitulacion = ref<boolean>();
    const idioma = ref<Array<string>>();
    const experienciaCuidadoMenores = ref<string>();
    const disponiblidadEspecial = ref<Array<string>>();
    const verSoloDisponibles = ref<boolean>(false);

    const profesionalesFiltrados = computed(() => {
      /* INFO: se filtrarían los profesionales asignados del listado de los profesionales para el servicio. No se aplica porque el profesional asignado, en el listado ya aparecería como no disponible, además de los mensajes de confirmación para que el gestor de la comarca contacte con el profesional antes de asignarlo al servicio.
      profesionalesListado.value.filter((pl) =>
        profesionalesAsignadosFilas.value.some((pa) => pa.id != pl.id)
      );*/
      return profesionalesListado.value
        .filter((p) => {
          if (vehiculoPropio.value && p.vehiculo != vehiculoPropio.value) {
            return false;
          }
          if (
            idioma.value &&
            idioma.value.length > 0 &&
            !idioma.value.every((i) =>
              p.idiomas.some((j) => j.nombreIdioma === i)
            )
          ) {
            return false;
          }
          if (
            typeof disponeTitulacion.value !== "undefined" &&
            p.disponeTitulacion != disponeTitulacion.value
          ) {
            return false;
          }
          if (
            typeof experienciaCuidadoMenores.value !== "undefined" &&
            experienciaCuidadoMenores.value !== "" &&
            p.experienciaCuidadoMenores != experienciaCuidadoMenores.value
          ) {
            return false;
          }
          if (
            disponiblidadEspecial.value &&
            disponiblidadEspecial.value.length > 0 &&
            !disponiblidadEspecial.value.every((d) => p[d] == true)
          ) {
            return false;
          }
          if (verSoloDisponibles.value === true && !p.disponible) {
            return false;
          }
          return true;
        })
        .sort((a, b) => +b.disponible - +a.disponible);
    });

    // Adjuntos.
    const documentos = ref<Documento[]>([]);
    const adjuntosEliminar = ref<string[]>([]);
    const {
      nuevosArchivos: adjuntosNuevos,
      deleteAdjuntoNuevo,
      includeAdjuntoNuevo,
    } = useDropZone("#kt_dropzonejs_nuevos_adjuntos");
    const removeDocumento = (index) => {
      let documentoId = documentos.value[index].id as string;
      if (!adjuntosEliminar.value?.length) {
        adjuntosEliminar.value = [];
      }
      adjuntosEliminar.value.push(documentoId);
      documentos.value.splice(index, 1);
    };

    // Fechas.
    const { value: inicioPrevisto, setValue: setInicioPrevisto } =
      useField<Date>("inicioPrevisto");
    const { value: finPrevisto, setValue: setFinPrevisto } =
      useField<Date>("finPrevisto");
    const { value: tiempos } = useField<Disponibilidad[]>("tiempos");
    const { value: fechas } = useField<Reserva[]>("fechas");
    const fechasPrevistas = computed({
      get(): Date[] {
        if (!inicioPrevisto.value || !finPrevisto.value) return [];

        return [inicioPrevisto.value, finPrevisto.value];
      },
      set(newValue: Date[]) {
        if (newValue.length != 2) return;

        const [inicio, fin] = newValue;
        setInicioPrevisto(inicio);
        setFinPrevisto(fin);
      },
    });

    const fechasModificadas = ref(false);

    const addHorario = () => {
      if (!tiempos.value) {
        tiempos.value = [];
      }
      tiempos.value.push({ dias: [], horas: [] });
    };
    const removeHorario = (index) => {
      if (tiempos.value.length > 1) {
        tiempos.value.splice(index, 1);
      } else {
        tiempos.value = [{ dias: [], horas: [] }];
      }
    };
    const borrarFecha = (index) => {
      fechas.value.splice(index, 1);
      fechasModificadas.value = true;
    };
    const generarFechas = () => {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      // Mostrar aviso especial de si hay profesional asignado, que se va a perder la configuracion
      if (profesionalesAsignados.value) {
        swalWithBootstrapButtons
          .fire({
            title: t(
              "dialogs.modificarNuevasFechasServicioConProfesional.title"
            ),
            width: 600,
            html: t("dialogs.modificarNuevasFechasServicioConProfesional.body"),
            icon: "question",
            showCancelButton: true,
            confirmButtonText: t(
              "dialogs.modificarNuevasFechasServicioConProfesional.okButton"
            ),
            cancelButtonText: t("dialogs.cancelButton"),
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              fechasModificadas.value = true;
              var d = moment(inicioPrevisto.value);
              var dDay = d.clone().hour(0).minutes(0);
              var until = moment(finPrevisto.value).endOf("day");
              const toInsert: Reserva[] = [];

              for (; dDay.isSameOrBefore(until); dDay.add(1, "day")) {
                let bloquesDias = tiempos.value?.map((t) => t.dias);
                let bloquesHoras = tiempos.value?.map((t) => t.horas);
                if (bloquesDias) {
                  bloquesDias.forEach(function (bloqueDia, index) {
                    let bloqueDiaArray = Object.values(bloqueDia);
                    if (bloqueDiaArray.includes(dDay.isoWeekday())) {
                      for (let hora of bloquesHoras[index]) {
                        let fecha = moment(dDay).add(hora, "hours").format();
                        let row = {
                          fechaToShow: dDay.format("D/M/Y"),
                          fecha: fecha,
                          diaSemana: dDay.locale("es").format("dddd"),
                          hora: horarios.filter((h) => h.id == hora)[0]["text"],
                        };
                        toInsert.push(row);
                      }
                    }
                  });
                }
              }
              fechas.value = toInsert;
            }
          });
      }
      // Mostrar aviso especial de si hay fechas.length > 0 se va perder la configuracion
      if (fechas?.value?.length > 0 && profesionalesAsignados.value == false) {
        swalWithBootstrapButtons
          .fire({
            title: t("dialogs.modificarNuevasFechasServicio.title"),
            html: t("dialogs.modificarNuevasFechasServicio.body"),
            width: 600,
            icon: "question",
            showCancelButton: true,
            confirmButtonText: t(
              "dialogs.modificarNuevasFechasServicio.okButton"
            ),
            cancelButtonText: t("dialogs.cancelButton"),
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              fechasModificadas.value = true;
              var d = moment(inicioPrevisto.value);
              var dDay = d.clone().hour(0).minutes(0);
              var until = moment(finPrevisto.value).endOf("day");
              const toInsert: Reserva[] = [];

              for (; dDay.isSameOrBefore(until); dDay.add(1, "day")) {
                let bloquesDias = tiempos.value?.map((t) => t.dias);
                let bloquesHoras = tiempos.value?.map((t) => t.horas);
                if (bloquesDias) {
                  bloquesDias.forEach(function (bloqueDia, index) {
                    let bloqueDiaArray = Object.values(bloqueDia);
                    if (bloqueDiaArray.includes(dDay.isoWeekday())) {
                      for (let hora of bloquesHoras[index]) {
                        let fecha = moment(dDay).add(hora, "hours").format();
                        let row = {
                          fechaToShow: dDay.format("D/M/Y"),
                          fecha: fecha,
                          diaSemana: dDay.locale("es").format("dddd"),
                          hora: horarios.filter((h) => h.id == hora)[0]["text"],
                        };
                        toInsert.push(row);
                      }
                    }
                  });
                }
              }
              fechas.value = toInsert;
            }
          });
      }
      // Si no tenía fechas ni profesional, no mostrar aviso y generar Fechas
      if (
        profesionalesAsignados.value == false &&
        (typeof fechas?.value?.length === "undefined" ||
          fechas?.value?.length == 0)
      ) {
        fechasModificadas.value = true;
        var d = moment(inicioPrevisto.value);
        var dDay = d.clone().hour(0).minutes(0);
        var until = moment(finPrevisto.value).endOf("day");
        const toInsert: Reserva[] = [];

        for (; dDay.isSameOrBefore(until); dDay.add(1, "day")) {
          let bloquesDias = tiempos.value?.map((t) => t.dias);
          let bloquesHoras = tiempos.value?.map((t) => t.horas);
          if (bloquesDias) {
            bloquesDias.forEach(function (bloqueDia, index) {
              let bloqueDiaArray = Object.values(bloqueDia);
              if (bloqueDiaArray.includes(dDay.isoWeekday())) {
                for (let hora of bloquesHoras[index]) {
                  let fecha = moment(dDay).add(hora, "hours").format();
                  let row = {
                    fechaToShow: dDay.format("D/M/Y"),
                    fecha: fecha,
                    diaSemana: dDay.locale("es").format("dddd"),
                    hora: horarios.filter((h) => h.id == hora)[0]["text"],
                  };
                  toInsert.push(row);
                }
              }
            });
          }
        }
        fechas.value = toInsert;
      }
    };

    // Drawer.
    const drawer = ref(false);
    const drawerAceptar = (fecha) => {
      fechas.value.push(fecha);
      drawer.value = false;
      fechasModificadas.value = true;
    };
    const drawerCerrar = () => {
      drawer.value = false;
    };

    return {
      changeComarca: async (newValue) => {
        await proyectosModule.fetchByComarca({
          comarcaId: newValue,
          comarcaIdFiltro: module.servicio?.comarcaId as string,
        });
      },
      Link,
      text,
      copy,
      copied,
      isSupported,
      errors,
      isCreate,
      fechasModificadas,
      profesionalesAsignados,
      profesionalesAsignadosFilas,
      busquedaProfesional,
      lugaresEspaciosColectivos,
      iconosServicios,
      solicitudes,
      menores,
      comarcas,
      vehiculos,
      nombreIdioma,
      comarcaValor,
      servicioId,
      vehiculoPropio,
      disponeTitulacion,
      idioma,
      experienciaCuidadoMenores,
      disponiblidadEspecial,
      verSoloDisponibles,
      profesionalesFiltrados,
      documentos,
      deleteAdjuntoNuevo,
      includeAdjuntoNuevo,
      comarcaId,
      iconoCls,
      nombre,
      descripcion,
      lugarPrestacion,
      lugarEspacioColectivo,
      otrosEspacioColectivo,
      plazas,
      direccion,
      inscripcionesAbiertas,
      url,
      fechasPrevistas,
      tiempos,
      fechas,
      addHorario,
      removeHorario,
      borrarFecha,
      generarFechas,
      dias,
      horarios,
      drawer,
      drawerAceptar,
      drawerCerrar,
      adjuntosNuevos,
      removeDocumento,
      isAdmin: !userComarcaId,
      inicioPrevisto,
      finPrevisto,
      inicioPrevistoFormateado: computed(() => {
        return moment(inicioPrevisto.value).format("D/M/Y");
      }),
      finPrevistoFormateado: computed(() => {
        return moment(finPrevisto.value).format("D/M/Y");
      }),
      fechaEnvioEncuesta: computed(() => {
        if (!datosServicio.value?.servicio?.fechaEnvioEncuesta) return "";

        return moment(datosServicio.value?.servicio?.fechaEnvioEncuesta).format(
          "D/M/Y"
        );
      }),
      enableTabProfesional: computed(() => {
        return fechas?.value?.length > 0 && fechasModificadas.value == false
          ? true
          : false;
      }),
      cargaDetalle,
      cargaListadoProfesionales,
      crearServicioProgramado,
      actualizarServicioProgramado,
      tabClick: async (tab) => {
        if (tab.paneName === "profesionales") {
          cargaListadoProfesionales.value = true;
          try {
            await module.fetchServicioProfesionales({
              comarcaId: comarcaValor.value,
              id: servicioId.value as string,
            });
          } catch (err) {
            Swal.fire({
              text: t("formularios.profesionalesListado.cargar.error"),
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: t("dialogs.okButton"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          } finally {
            cargaListadoProfesionales.value = false;
          }
        }
      },
      submit: handleSubmit(async (values) => {
        if (isCreate) {
          crearServicioProgramado.value = true;
          try {
            await module
              .crear({
                comarcaId: userComarcaId ?? values.comarcaId,
                servicio: {
                  iconoCls: values.iconoCls,
                  nombre: values.nombre,
                  descripcion: values.descripcion,
                  plazas: values.plazas,
                  direccion: values.direccion,
                  inscripcionAbierta: values.inscripcionesAbiertas,
                  lugarPrestacion: "ESPACIO",
                  lugarEspacioColectivo: values.lugarEspacioColectivo,
                  otrosEspacioColectivo: values.otrosEspacioColectivo,
                  inicioPrevisto: values.inicioPrevisto,
                  finPrevisto: values.finPrevisto,
                  fechas: values.fechas,
                  tiempos: values.tiempos,
                  adjuntosNuevos: adjuntosNuevos.value,
                  proyectoId: values.proyectoId,
                },
              })
              .then(() => {
                if (!module.errors)
                  ElNotification({
                    message: t(
                      "formularios.servicioProgramado.crear.mensaje.exito"
                    ),
                    type: "success",
                  });
                router.replace("/listados/servicios-programados");
              });
          } catch (err) {
            ElNotification({
              message: t("formularios.servicioProgramadoDetalle.crear.error"),
              type: "error",
            });
          } finally {
            crearServicioProgramado.value = false;
          }
        } else {
          actualizarServicioProgramado.value = true;
          try {
            await module
              .update({
                comarcaId: values.comarcaId as string,
                id: token as string,
                servicio: {
                  iconoCls: values.iconoCls,
                  nombre: values.nombre,
                  descripcion: values.descripcion,
                  plazas: values.plazas,
                  direccion: values.direccion,
                  inscripcionAbierta: values.inscripcionesAbiertas,
                  lugarPrestacion: "ESPACIO",
                  lugarEspacioColectivo: values.lugarEspacioColectivo,
                  otrosEspacioColectivo: values.otrosEspacioColectivo,
                  inicioPrevisto: values.inicioPrevisto,
                  finPrevisto: values.finPrevisto,
                  fechas: values.fechas,
                  tiempos: values.tiempos,
                  adjuntosNuevos: adjuntosNuevos.value,
                  adjuntosEliminar: adjuntosEliminar.value ?? [],
                  proyectoId: values.proyectoId,
                },
              })
              .then(() => {
                if (!module.errors)
                  ElNotification({
                    message: t(
                      "formularios.servicioProgramado.guardar.mensaje.exito"
                    ),
                    type: "success",
                  });
                //router.replace("/listados/servicios-programados");
                fechasModificadas.value = false;
              });
          } catch (err) {
            ElNotification({
              message: t("formularios.servicioProgramadoDetalle.editar.error"),
              type: "error",
            });
          } finally {
            actualizarServicioProgramado.value = false;
          }
        }
      }),
      enviarEncuestaTodos: () => {
        cargaDetalle.value = true;
        module
          .enviarCorreoEncuestaTodos({
            comarcaId: datosServicio.value?.comarcaId as string,
            id: token as string,
          })
          .then(() => {
            if (datosServicio.value?.servicio?.fechaEnvioEncuesta) {
              datosServicio.value.servicio.fechaEnvioEncuesta = new Date();
            }
            cargaDetalle.value = false;
            return ElNotification({
              message: t(
                "formularios.servicioProgramado.encuestas.todos.mensaje.exito"
              ),
              type: "success",
            });
          })
          .catch((err) => {
            cargaDetalle.value = false;
            ElNotification({
              message: t(
                "formularios.servicioProgramado.encuestas.mensaje.error"
              ),
              type: "error",
            });
            console.error(err);
          });
      },
      enviarEncuesta: (row) => {
        cargaDetalle.value = true;
        module
          .enviarCorreoEncuesta({
            comarcaId: datosServicio.value?.comarcaId as string,
            id: token as string,
            familiaId: row.familiaId,
          })
          .then(() => {
            cargaDetalle.value = false;
            return ElNotification({
              message: t(
                "formularios.servicioProgramado.encuestas.individual.mensaje.exito"
              ),
              type: "success",
            });
          })
          .catch((err) => {
            cargaDetalle.value = false;
            ElNotification({
              message: t(
                "formularios.servicioProgramado.encuestas.mensaje.error"
              ),
              type: "error",
            });
            console.error(err);
          });
      },
      verComentario: (row) => {
        const html =
          `<div style='text-align:left;font-weight:500'><div>` +
          t("dialogs.respuestaEncuesta.responsable") +
          ": " +
          row.nombre +
          " " +
          row.apellidos +
          `</div><div>` +
          t("dialogs.respuestaEncuesta.correoElectronico") +
          ": " +
          row.email +
          `</div><div>` +
          t("dialogs.respuestaEncuesta.telefono") +
          ": " +
          row.telefono +
          `</div><div>` +
          t("dialogs.respuestaEncuesta.fecha") +
          ": " +
          new Date(row.respuestaEncuesta.createdOn).toLocaleDateString(
            "es-ES"
          ) +
          `</div><hr><div>` +
          t("dialogs.respuestaEncuesta.satisfaccion") +
          ": " +
          row.respuestaEncuesta.puntuacion +
          `/5</div><div>` +
          row.respuestaEncuesta.comentario +
          `</div></div>`;

        Swal.fire({
          title: t("dialogs.respuestaEncuesta.title"),
          html: html,
          width: 600,
          icon: "info",
          showCancelButton: false,
          confirmButtonText: t("dialogs.respuestaEncuesta.okButton"),
        });
      },
      satisfaccion: computed(() => {
        const respuestas = menores.value.filter((ele) => ele.respuestaEncuesta);
        const total = respuestas.reduce(
          (prev, v) => prev + (v.respuestaEncuesta?.puntuacion ?? 0),
          0
        );

        return {
          total,
          respuestas: respuestas.length,
          media:
            total === 0
              ? 0
              : parseFloat((total / respuestas.length).toFixed(1)),
        };
      }),
      eliminar: () => {
        Swal.fire({
          text: t("dialogs.eliminar.confirmacion"),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: t("dialogs.okButton"),
          cancelButtonText: t("dialogs.cancelButton"),
          reverseButtons: true,
        }).then(({ isConfirmed }) => {
          if (!isConfirmed) return;

          cargaDetalle.value = true;
          return module
            .eliminar({
              comarcaId: datosServicio.value?.comarcaId as string,
              elementId: token as string,
            })
            .then(() => {
              router.replace("/listados/servicios-programados");
              ElMessage({
                message: t("dialogs.eliminar.exito"),
                type: "success",
              });
            })
            .catch((err) => {
              Swal.fire({
                text: t("dialogs.eliminar.error"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              console.error(err);
            })
            .finally(() => {
              cargaDetalle.value = false;
            });
        });
      },
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      proyectoId: proyectoId,
      proyectosN2,
    };
  },
});
